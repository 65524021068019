import Inputmask from 'inputmask'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('inputmask', {
    mounted(el, binding) {
      const element = el.tagName !== 'input' ? el.getElementsByTagName('input')[0] : el

      new Inputmask({
        showMaskOnHover: false,
        showMaskOnFocus: false,
        ...binding.value,
      }).mask(element)
    },
  })
})
