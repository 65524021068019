import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCompoundCalculator, LazyPageTitle, LazyUTable, LazyFaq, LazyArticleLayout, LazyCategoryLayout, LazyDefaultLayout, LazyHomeLayout, LazyArticleAuthor, LazyCtaWidget, LazyCategoryAlternativeCard, LazyHomeSection, LazyHomeSectionContent, LazyUBreadcrumbs, LazyUBtn, LazyUBtnGroup, LazyUFeatureCard, LazyUInfoCard, LazyUCheckbox, LazyUExpansionPanel, LazyUExpansionPanels, LazyUIcon, LazyULink, LazyUText, LazyUTextField, LazyLogoConsumer, LazyLogoPro } from '#components'
const lazyGlobalComponents = [
  ["CompoundCalculator", LazyCompoundCalculator],
["PageTitle", LazyPageTitle],
["UTable", LazyUTable],
["Faq", LazyFaq],
["ArticleLayout", LazyArticleLayout],
["CategoryLayout", LazyCategoryLayout],
["DefaultLayout", LazyDefaultLayout],
["HomeLayout", LazyHomeLayout],
["ArticleAuthor", LazyArticleAuthor],
["CtaWidget", LazyCtaWidget],
["CategoryAlternativeCard", LazyCategoryAlternativeCard],
["HomeSection", LazyHomeSection],
["HomeSectionContent", LazyHomeSectionContent],
["UBreadcrumbs", LazyUBreadcrumbs],
["UBtn", LazyUBtn],
["UBtnGroup", LazyUBtnGroup],
["UFeatureCard", LazyUFeatureCard],
["UInfoCard", LazyUInfoCard],
["UCheckbox", LazyUCheckbox],
["UExpansionPanel", LazyUExpansionPanel],
["UExpansionPanels", LazyUExpansionPanels],
["UIcon", LazyUIcon],
["ULink", LazyULink],
["UText", LazyUText],
["UTextField", LazyUTextField],
["LogoConsumer", LazyLogoConsumer],
["LogoPro", LazyLogoPro],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
